import { useServices } from '@/hooks';
import { KeyboardShortcut, KeyboardShortcutConfiguration } from '@/utils';
import {
  BaseDialogActionButtonConfiguration,
  CloseDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  DialogViewModel
} from '@/viewmodels';
import { Alert, Stack, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { FormEventHandler, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ResponsiveDialog } from '../../ResponsiveDialog';
import { Form } from '../Form';
import { KeyboardShortcutsListener } from '../KeyboardShortcutsListener';
import { ConfirmationDialog } from '../confirmationdialog';
import { UpdatablePresenter } from '../updatablepresenter';
import { DialogActionButton } from './DialogActionButton';
import { DialogActions } from './DialogActions';
import { DialogContent, DialogContentPadding } from './DialogContent';
import { ResponsiveDialogHeader } from './ResponsiveDialogHeader';

const fullScreenKeyboardShortcutConfig: KeyboardShortcutConfiguration = {
  alt: false,
  code: 'KeyF',
  ctrl: true,
  enabled: true,
  supportMetaAsCtrl: false,
  shift: true
};

const defaultSubmitKeyboardShortcutConfig: KeyboardShortcutConfiguration = {
  alt: false,
  code: 'Enter',
  ctrl: true,
  enabled: true,
  supportMetaAsCtrl: true,
  shift: false
};

export type DialogWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface DialogAdditionalAction {
  readonly title: string;
  readonly icon: ReactElement;
  readonly action: () => Promise<void>;
  readonly isDestructive?: boolean;
  readonly isHidden?: boolean;
}

interface HeaderButtons {
  leftButtons: DialogActionButtonConfiguration[];
  rightButtons: DialogActionButtonConfiguration[];
}

export interface DialogProps {
  sx?: SxProps;
  className?: string;
  viewModel: DialogViewModel;
  isOpen: boolean;

  /**
   * Title displayed in the dialog header.
   */
  title: string;

  /**
   * Width of the dialog.
   */
  width: DialogWidth;

  /**
   * The max full screen width. Optional. Used to determine if the dialog is in full screen.
   * The `maxWidth` will be used if not specified.
   */
  fullScreenWidth?: DialogWidth;

  /**
   * Should display the dialog at fullHeight even when not fullscreen.
   */
  fullHeight?: boolean;

  /**
   * The header icon. Optional.
   */
  icon?: ReactNode;

  /**
   * Indicates to disable closing the dialog. Optional. Default is false.
   */
  disableClose?: boolean;

  /**
   * Content to display.
   */
  renderData: () => ReactNode;

  /**
   * Callback when submitting the form.
   */
  onSubmit?: FormEventHandler<HTMLFormElement>;

  /**
   * Callback when "cmd+Enter" or "control+Enter" is pressed.
   */
  submit?: () => void;

  /**
   * Custom shortcut used to trigger the `submit` function.
   */
  customSubmitShortcut?: KeyboardShortcutConfiguration;

  /**
   * Indicates whether the modal should add a fullscreen button.
   */
  canToggleFullscreen?: boolean;

  /**
   * Overrides the default value for the padding of the content.
   */
  contentPadding?: DialogContentPadding;

  /**
   * Additional actions displayed as a more icon button in the header.
   */
  additionalActions?: DialogAdditionalAction[];

  /**
   * If true, the dialog will be draggable if it's not fullscreen.
   */
  isDraggable?: boolean;

  /**
   * If view model is updatable, determines if root view should have `display: flex`.
   */
  isContentDisplayFlex?: boolean;

  /**
   * If set to true, the overflow of the underlying Paper component will be set to hidden.
   */
  disableOverflow?: boolean;
}

export const Dialog = observer(
  ({
    sx,
    className,
    isOpen,
    title,
    viewModel,
    renderData,
    width,
    fullScreenWidth,
    fullHeight,
    icon,
    disableClose,
    onSubmit,
    canToggleFullscreen,
    submit,
    customSubmitShortcut,
    contentPadding,
    additionalActions,
    isDraggable = true,
    isContentDisplayFlex,
    disableOverflow = false
  }: DialogProps) => {
    const { localization, autoSync } = useServices();
    const strings = localization.localizedStrings.utils;
    const theme = useTheme();

    useEffect(() => {
      autoSync.suspendSync();
      return () => autoSync.resumeSync();
    }, []);

    const [fullScreen, setFullScreen] = useState(false);
    const toggleFullscreen = () => (canToggleFullscreen ? setFullScreen(!fullScreen) : setFullScreen(false));
    const fullScreenShortcut: KeyboardShortcut = {
      configuration: fullScreenKeyboardShortcutConfig,
      action: toggleFullscreen
    };

    const defaultCloseButtonConfig = useMemo(
      () => new CloseDialogActionButtonConfiguration('main', localization, () => viewModel.dismiss()),
      [localization.localizedStrings]
    );

    const canSubmit = (viewModel.kind === 'static' || viewModel.hasData) && submit != null;
    // Action triggered by "cmd+Enter" or "control+Enter".
    const submitKeyDownAction = () => {
      if (canSubmit) {
        submit?.();
      }
    };

    const submitShortcut: KeyboardShortcut | undefined = canSubmit
      ? {
          configuration: customSubmitShortcut ?? defaultSubmitKeyboardShortcutConfig,
          action: () => submitKeyDownAction()
        }
      : undefined;

    const fullScreenButtonConfig = useMemo(
      () =>
        new BaseDialogActionButtonConfiguration(
          'secondary',
          'top-only',
          'hidden',
          fullScreen ? 'reduce' : 'fullscreen',
          undefined,
          () => '', // No title, will always be shown as an icon.
          'contained',
          () => {
            toggleFullscreen();
            return Promise.resolve();
          },
          () => {
            const strings = localization.localizedStrings.utils;
            return fullScreen ? strings.dialogReduceSizeButtonTooltip : strings.dialogFullScreenButtonTooltip;
          }
        ),
      [fullScreen]
    );

    const headerButtons: () => HeaderButtons = () => {
      if (viewModel.kind === 'updatable' && viewModel.state !== 'fulfilled' && !viewModel.hasData) {
        return { leftButtons: [defaultCloseButtonConfig], rightButtons: [] };
      }

      const leftActions = viewModel.actions.filter((a) => a.mobilePlacement === 'left');
      const rightActions = viewModel.actions.filter((a) => a.mobilePlacement === 'right');
      const leftSupplementaryActions = viewModel.supplementaryActions.filter((a) => a.mobilePlacement === 'left');
      const rightSupplementaryActions = viewModel.supplementaryActions.filter((a) => a.mobilePlacement === 'right');

      if (canToggleFullscreen) {
        rightSupplementaryActions.unshift(fullScreenButtonConfig);
      }

      const allLeftActions = leftActions.concat(leftSupplementaryActions);
      const allRightActions = rightSupplementaryActions.concat(rightActions);

      const resolvedLeftActions =
        leftActions.length === 0 && rightActions.length === 0
          ? // Casting to silence error type error (it would work though).
            ([defaultCloseButtonConfig] as DialogActionButtonConfiguration[]).concat(leftSupplementaryActions)
          : allLeftActions;

      return {
        leftButtons: resolvedLeftActions,
        rightButtons: allRightActions
      };
    };

    const leftAlertPadding = contentPadding?.left === 0 || isContentDisplayFlex ? 2 : 0;
    const rightAlertPadding = contentPadding?.right === 0 || isContentDisplayFlex ? 2 : 0;

    const showDialogActionsMediaQuery = theme.breakpoints.up(fullScreenWidth ?? width);
    const showDialogActions = useMediaQuery(showDialogActionsMediaQuery);

    const id = useMemo(() => uuidv4(), []);
    const headerId = `draggable-dialog-${id}`;

    return (
      <KeyboardShortcutsListener shortcuts={[fullScreenShortcut, submitShortcut]}>
        <ResponsiveDialog
          sx={sx}
          className={className}
          open={isOpen}
          maxWidth={width}
          fullScreenWidth={fullScreenWidth}
          fullWidth
          fullHeight={fullHeight}
          fullScreen={fullScreen || undefined}
          disableClose={disableClose}
          onClose={() => void viewModel.dismiss()} // Called for backgroundClick and escapeKeyDown.
          isDraggable={isDraggable}
          ariaLabelledBy={headerId}
          PaperProps={{ sx: { overflow: disableOverflow ? 'hidden' : undefined } }}
        >
          <ResponsiveDialogHeader
            title={title}
            icon={icon}
            {...headerButtons()}
            fullScreenWidth={fullScreenWidth ?? width}
            additionalActions={additionalActions}
            isDraggable={isDraggable}
            id={headerId}
          />

          <DialogContent padding={contentPadding}>
            <Form
              style={{ flex: 1, display: 'flex', flexDirection: 'column', height: fullHeight ? '100%' : undefined }}
              onSubmit={onSubmit}
            >
              {viewModel.kind === 'static' && renderData()}
              {viewModel.kind === 'updatable' && (
                <>
                  <UpdatablePresenter
                    viewModel={viewModel}
                    isFlex={isContentDisplayFlex}
                    sx={{ height: fullHeight ? '100%' : undefined }}
                    renderData={() => renderData()}
                    errorMessageSelector={(error) => viewModel.errorMessageSelector?.(error)}
                  />
                  {viewModel.showDismissConfirmAlert && (
                    <ConfirmationDialog
                      isOpen={true}
                      message={strings.updatableDialogDismissConfirmationMessageButton}
                      onSubmit={(result) => {
                        viewModel.closeDismissConfirmAlert();

                        if (result) {
                          void viewModel.forceDismiss();
                        }
                      }}
                      confirmButtonLabel={strings.updatableDialogDismissConfirmationDiscardButton}
                      cancelButtonLabel={strings.updatableDialogDismissConfirmationCancelButton}
                    />
                  )}
                </>
              )}
              {viewModel.error && (viewModel.kind === 'static' || viewModel.state === 'fulfilled') && (
                <Alert
                  variant="filled"
                  severity="error"
                  onClose={() => viewModel.clearError()}
                  sx={{
                    mt: { xs: 3, sm: 1 },
                    ml: leftAlertPadding,
                    mr: rightAlertPadding,
                    position: disableOverflow ? 'absolute' : 'sticky',
                    left: 0,
                    right: 0,
                    bottom: disableOverflow ? 60 : 0
                  }}
                >
                  {viewModel.error}
                </Alert>
              )}
            </Form>
          </DialogContent>

          {showDialogActions && (
            <DialogActions>
              <Stack flex={1} direction="row" spacing={1}>
                {(viewModel.kind === 'static' || viewModel.state === 'fulfilled' || viewModel.hasData) &&
                  viewModel.supplementaryActions
                    .filter((c) => c.placement !== 'top-only')
                    .map((config) => <DialogActionButton key={config.title()} configuration={config} />)}
              </Stack>

              {viewModel.kind === 'static' &&
                (viewModel.actions.length > 0 ? viewModel.actions : [defaultCloseButtonConfig]).map((config) => (
                  <DialogActionButton key={config.title()} configuration={config} />
                ))}

              {viewModel.kind === 'updatable' && (
                <>
                  {viewModel.state !== 'fulfilled' && !viewModel.hasData && (
                    <DialogActionButton configuration={defaultCloseButtonConfig} />
                  )}

                  {(viewModel.state === 'fulfilled' || viewModel.hasData) &&
                    (viewModel.actions.length > 0 ? viewModel.actions : [defaultCloseButtonConfig]).map((config) => {
                      return <DialogActionButton key={config.title()} configuration={config} />;
                    })}
                </>
              )}
            </DialogActions>
          )}
        </ResponsiveDialog>
      </KeyboardShortcutsListener>
    );
  }
);
