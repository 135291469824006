import { action, computed, makeObservable, observable, override, runInAction } from 'mobx';
import LocalizedStrings from 'strings';
import { ServiceContainer } from '../../providers';
import { LocalizationService } from '../../services';
import { SchoolDataStore } from '../../stores';
import {
  AppBaseStaticDialogViewModel,
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration,
  StaticDialogViewModel
} from '../utils';

const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export interface AdminSchoolInviteTeachersDialogViewModel extends StaticDialogViewModel {
  readonly emails: string[];
  isEmailValid(email: string): boolean;
  addEmail(email: string): void;
  removeEmail(email: string): void;
}

export class AppAdminSchoolInviteTeachersDialogViewModel
  extends AppBaseStaticDialogViewModel
  implements AdminSchoolInviteTeachersDialogViewModel
{
  private _emails = observable.set<string>();
  @observable private _isImporting = false;

  private readonly _saveButtonConfig: SaveDialogActionButtonConfiguration;
  private readonly _cancelButtonConfig: CancelDialogActionButtonConfiguration;

  constructor(
    private readonly _schoolId: string,
    onDismiss: () => Promise<void>,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore
  ) {
    super(onDismiss);
    makeObservable(this);

    this._cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
      this.dismiss()
    );

    this._saveButtonConfig = new SaveDialogActionButtonConfiguration(
      'main',
      this._localization,
      () => this.sendInvite(),
      () => LocalizedStrings.admin.teachersList.invite.submitButton(),
      'send'
    );
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._saveButtonConfig.showLoading = this._isImporting;
    this._saveButtonConfig.isEnabled = !this._isImporting && this._emails.size > 0;
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  @computed
  get emails(): string[] {
    // Displaying the last entered email at the top of the list.
    return Array.from(this._emails.values()).reverse();
  }

  isEmailValid(email: string): boolean {
    return emailRegex.test(email);
  }

  @action
  addEmail(email: string) {
    this._emails.add(email);
  }

  @action
  removeEmail(email: string) {
    this._emails.delete(email);
  }

  private async sendInvite() {
    runInAction(() => {
      this._isImporting = true;
      this._error = undefined;
    });

    try {
      await this._schoolStore.inviteTeachersToSchool(this._schoolId, this.emails);
      await this.dismiss();
    } catch {
      runInAction(() => (this._error = LocalizedStrings.admin.teachersList.invite.unexpectedErrorMessage()));
    } finally {
      runInAction(() => (this._isImporting = false));
    }
  }
}
