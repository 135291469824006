import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { differenceInCalendarDays } from 'date-fns';
import { computed, makeObservable, observable } from 'mobx';

import {
  dayToDate,
  plannerHasAccessKindsForUser,
  ScheduleCycleInfo,
  UserDashboard,
  UserDashboardKind,
  UserDashboardPlanner,
  UserDashboardSchool
} from '@/models';
import { ServiceContainer } from '@/providers';
import { DateService } from '@/services';
import { Loadable, ScheduleCyclesLoadable, UserDataStore } from '@/stores';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { chain } from 'lodash';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../index';

interface ScheduleCycleListLoadable {
  readonly userDashboard: UserDashboard;
  readonly loadable: ScheduleCyclesLoadable;
}

export interface ScheduleCycleListItemInfo {
  readonly id: string;
  readonly scheduleCycle: ScheduleCycleInfo;
  readonly userDashboard: UserDashboard;
  readonly isPast: boolean;
  readonly canBeIgnored: boolean;
  readonly canToggleIsIgnoredValue: boolean;
  readonly isIgnored: boolean;
  toggleIsIgnored(): void;
}

export interface ScheduleCycleListViewModel extends UpdatableViewModel {
  readonly isReadOnly: boolean;
  readonly canCreatePersonalScheduleCycle: boolean;
  showPastSchedules: boolean;
  schedules: ScheduleCycleListItemInfo[];
}

export class AppScheduleCycleListViewModel extends BaseUpdatableViewModel implements ScheduleCycleListViewModel {
  @observable private _showPastSchedules;
  private readonly _scheduleCyclesLoadables: ScheduleCycleListLoadable[];

  constructor(
    private readonly _dashboardId: string,
    private readonly _dashboardKind: UserDashboardKind,
    initialShowPastSchedulesValue: boolean,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _dateService: DateService = ServiceContainer.services.dateService
  ) {
    super();
    makeObservable(this);
    this._showPastSchedules = initialShowPastSchedulesValue;

    const dashboard = this.currentDashboard;

    switch (dashboard.kind) {
      case 'planner': {
        this._scheduleCyclesLoadables = this._userStore.getScheduleCyclesForPlannerAndItsSchools(dashboard.id);
        break;
      }

      case 'school': {
        this._scheduleCyclesLoadables = [
          {
            userDashboard: dashboard,
            loadable: this._userStore.getScheduleCycles(dashboard.school.school?.scheduleCycleIds ?? [])
          }
        ];
        break;
      }
    }
  }

  @computed
  private get currentDashboard(): UserDashboard {
    switch (this._dashboardKind) {
      case 'planner': {
        const planner = this._userStore.getPlannerForId(this._dashboardId)!;
        return { id: planner.id, title: planner.title, kind: 'planner', planner } as UserDashboardPlanner;
      }

      case 'school': {
        const school = this._userStore.getSchoolForId(this._dashboardId)!;
        return { id: school.school!.id, title: school.school!.name, kind: 'school', school } as UserDashboardSchool;
      }
    }
  }

  @computed
  private get ignoredScheduleCycleIds(): string[] {
    switch (this.currentDashboard.kind) {
      case 'planner':
        return this.currentDashboard.planner.ignoredScheduleCycleIds;
      case 'school':
        return [];
    }
  }

  @computed
  protected get loadables(): Loadable<unknown>[] {
    return this._scheduleCyclesLoadables.map((l) => l.loadable);
  }

  @computed
  get canCreatePersonalScheduleCycle(): boolean {
    if (!this.hasData || this.isReadOnly) {
      return false;
    }

    switch (this.currentDashboard.kind) {
      case 'planner': {
        const { schoolIds } = this.currentDashboard.planner;

        if (schoolIds.length > 0) {
          return this._userStore.schools.find((s) => !s.school!.isArchived && schoolIds.includes(s.school!.id)) != null;
        }

        return this.schedules.find((s) => s.userDashboard.kind === 'planner' && !s.isPast) == null;
      }

      case 'school':
        return false;
    }
  }

  @computed
  get schedules(): ScheduleCycleListItemInfo[] {
    const ignoredScheduleCycleIds = this.ignoredScheduleCycleIds;
    const canIgnoreScheduleCycle = this._dashboardKind === 'planner';

    const canToggleIsIgnoredValue =
      this.currentDashboard.kind === 'planner'
        ? plannerHasAccessKindsForUser(
            this._userStore.user.userId,
            this.currentDashboard.planner,
            AccessKind.FULL_ACCESS
          )
        : false;

    return chain(this._scheduleCyclesLoadables)
      .reduce((previousValue, currentValue) => {
        const infos = currentValue.loadable.data.map<ScheduleCycleListItemInfo>((s) => ({
          id: s.scheduleCycle.id,
          userDashboard: currentValue.userDashboard,
          scheduleCycle: s,
          isPast: this.getIsScheduleCycleInThePast(s.scheduleCycle),
          canBeIgnored: canIgnoreScheduleCycle,
          canToggleIsIgnoredValue,
          isIgnored: ignoredScheduleCycleIds.includes(s.scheduleCycle.id),
          toggleIsIgnored: () => void this.toggleIsScheduleCycleIgnored(s.scheduleCycle.id)
        }));

        previousValue.push(...infos);
        return previousValue;
      }, [] as ScheduleCycleListItemInfo[])
      .uniqBy((s) => s.id)
      .orderBy((s) => dayToDate(s.scheduleCycle.scheduleCycle.endDay!), 'desc')
      .value();
  }

  @computed
  get isReadOnly(): boolean {
    const { userId } = this._userStore.user;

    switch (this.currentDashboard.kind) {
      case 'planner':
        return !plannerHasAccessKindsForUser(userId, this.currentDashboard.planner, AccessKind.FULL_ACCESS);

      case 'school':
        return !this.currentDashboard.school.administrators.some((a) => a.userId === userId);
    }
  }

  @computed
  get showPastSchedules(): boolean {
    return this._showPastSchedules;
  }

  set showPastSchedules(value: boolean) {
    this._showPastSchedules = value;
  }

  private async toggleIsScheduleCycleIgnored(id: string) {
    if (this._dashboardKind !== 'planner') {
      return;
    }

    await (this.ignoredScheduleCycleIds.includes(id)
      ? this._userStore.restoreScheduleCycleInPlanner(this._dashboardId, id)
      : this._userStore.ignoreScheduleCycleInPlanner(this._dashboardId, id));
  }

  private getIsScheduleCycleInThePast(schedule: ScheduleCycle): boolean {
    return differenceInCalendarDays(this._dateService.now, dayToDate(schedule.endDay!)) > 0;
  }
}
