import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { Note } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { RichText } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/rich_text_pb';
import { TextFormat } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/text_format_pb';
import { PartialMessage } from '@bufbuild/protobuf';
import { action, computed, makeObservable, observable, override, runInAction } from 'mobx';
import { Location } from 'react-router';
import { NavigateFunction } from 'react-router-dom';
import { BackgroundLocationState } from '../../BackgroundLocationState';
import { EditableNote, TimeOfDay } from '../../models';
import { ServiceContainer } from '../../providers';
import { LocalizationService, PasteboardService, UserDashboardPlannerItemsLocationState } from '../../services';
import {
  LoadableState,
  PlannerCalendarStore,
  PlannerDataStore,
  PlannerDetailedCourseSectionsLoadable,
  WorkDataStore,
  mergeLoadableStates
} from '../../stores';
import {
  AppBaseUpdatableDialogViewModel,
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration,
  UpdatableDialogViewModel
} from '../utils';

export interface NoteEditViewModel extends UpdatableDialogViewModel {
  readonly isApplying: boolean;
  readonly hasChanges: boolean;
  readonly allCourseSections: CourseSectionDetails[];
  text: string;
  readonly courseSection: CourseSectionDetails | undefined;
  time?: Date;
  isAllDay: boolean;
  readonly isNewNote: boolean;

  getDatesWithOccurrenceForCourseSection(fromDate: Date, toDate: Date): Date[];
  getCourseSectionOccurrencesStartTimeForDate(date: Date): TimeOfDay[];
  setCourseSection(courseSectionId: string | undefined): void;
  copyToPasteboard(): void;
  duplicate(navigate: NavigateFunction, location: Location): void;
  distribute(navigate: NavigateFunction, location: Location): void;
  repeat(navigate: NavigateFunction, location: Location): void;
  save(): Promise<void>;
  cancelNote(): Promise<boolean>;
}

export class AppNoteEditViewModel extends AppBaseUpdatableDialogViewModel implements NoteEditViewModel {
  @observable private _state: LoadableState = 'pending';
  @observable private _isApplying = false;
  @observable private _editableNote: EditableNote | undefined;

  private readonly _calendarStore: PlannerCalendarStore;
  private _saveButtonConfig = new SaveDialogActionButtonConfiguration('main', this._localization, () => this.save());
  private _cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
    this.dismiss()
  );

  @computed
  private get editableNote(): EditableNote {
    return this._editableNote!;
  }

  constructor(
    private readonly _noteId: string | undefined,
    private readonly _newNoteDefaultValues: PartialMessage<Note> | undefined,
    private readonly _plannerId: string,
    private readonly _onSuccess: (note: Note) => Promise<void>,
    onCancel: () => Promise<void>,
    localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _workStore: WorkDataStore = ServiceContainer.services.workStore,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _pasteboard: PasteboardService = ServiceContainer.services.pasteboard
  ) {
    super(localization, onCancel, true);
    this._calendarStore = _plannerStore.getCalendarStore(_plannerId);
    makeObservable(this);
  }

  @computed
  private get courseSectionsById(): PlannerDetailedCourseSectionsLoadable {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  get isNewNote(): boolean {
    return this._noteId == null;
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._cancelButtonConfig.isEnabled = !this.isApplying;
    // We always want the save button enabled for a new task (except while saving obviously).
    this._saveButtonConfig.isEnabled =
      !this.isApplying &&
      (this._noteId == null || this.hasChanges) &&
      this.editableNote.text != null &&
      this.editableNote.text.text.length > 0;
    this._saveButtonConfig.showLoading = this.isApplying;
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  @computed
  get state(): LoadableState {
    return mergeLoadableStates([this._state, this.courseSectionsById.state]);
  }

  @computed
  get hasData(): boolean {
    return this._editableNote != null && this.courseSectionsById.hasData;
  }

  @computed
  get isApplying(): boolean {
    return this._isApplying;
  }

  @computed
  get isSubmitting(): boolean {
    return this.isApplying;
  }

  @computed
  get hasChanges(): boolean {
    return this.editableNote.hasChanges;
  }

  @computed
  get text(): string {
    return this.editableNote.text?.text ?? '';
  }

  set text(value: string) {
    this.editableNote.text = new RichText({ text: value, format: TextFormat.PLAIN_TEXT });
  }

  @computed
  get allCourseSections(): CourseSectionDetails[] {
    return this.courseSectionsById.values.slice().sort(
      (cs1, cs2) =>
        cs1.courseSection!.title.localeCompare(cs2.courseSection!.title, this._localization.currentLocale, {
          sensitivity: 'base'
        }) ||
        cs1.courseSection!.section.localeCompare(cs2.courseSection!.section, this._localization.currentLocale, {
          sensitivity: 'base'
        })
    );
  }

  @computed
  get courseSection(): CourseSectionDetails | undefined {
    if (this.editableNote.courseSectionId == null) {
      return undefined;
    }

    return this.courseSectionsById.data.get(this.editableNote.courseSectionId);
  }

  @computed
  get time(): Date | undefined {
    return this.editableNote.time;
  }

  set time(value: Date | undefined) {
    this.editableNote.time = value;
  }

  @computed
  get isAllDay(): boolean {
    return this.editableNote.isAllDay;
  }

  set isAllDay(value: boolean) {
    this.editableNote.isAllDay = value;
  }

  setCourseSection(courseSectionId: string | undefined) {
    this.editableNote.courseSectionId = courseSectionId ?? '';
  }

  copyToPasteboard() {
    this._pasteboard.setContent({ case: 'note', value: this.editableNote.updatedModel });
    void this.dismiss();
  }

  duplicate(navigate: NavigateFunction, location: Location) {
    if (this._noteId != null) {
      this.showPlannerItemDialog(navigate, location, {
        noteEdit: {
          id: undefined,
          newNoteDefaultValues: { ...this._editableNote?.initialModel, isAllDay: true, time: undefined }
        }
      });
    }
  }

  distribute(navigate: NavigateFunction, location: Location) {
    if (this._noteId != null) {
      this.showPlannerItemDialog(navigate, location, { distributeItem: { kind: { case: 'note', id: this._noteId } } });
    }
  }

  repeat(navigate: NavigateFunction, location: Location) {
    if (this._noteId != null) {
      this.showPlannerItemDialog(navigate, location, { repeatItem: { kind: { case: 'note', id: this._noteId } } });
    }
  }

  @action
  async save() {
    if (this.isApplying || (!this.hasChanges && this._noteId != null)) {
      return;
    }

    try {
      this._error = undefined;
      this._isApplying = true;

      const isCreating = this._noteId == null;
      const updatedNote = this.editableNote.updatedModel;

      const result = await (isCreating
        ? this._workStore.createNote(updatedNote)
        : this._workStore.updateNote(updatedNote));

      await this._plannerStore.fetchPlannerContents(this._plannerId);

      runInAction(() => (this._isApplying = false));
      await this._onSuccess(result);
    } catch (e) {
      runInAction(() => {
        this._error = (e as Error).message;
        this._isApplying = false;
      });
    }
  }

  async cancelNote(): Promise<boolean> {
    if (this.isApplying || this._noteId == null) {
      return false;
    }

    try {
      this._error = undefined;
      this._isApplying = true;

      await this._workStore.setNoteStatus(this._noteId, true, this.editableNote.initialModel.syncToken);
      void this._plannerStore.fetchPlannerContents(this._plannerId);
      runInAction(() => (this._isApplying = false));
      return true;
    } catch (e) {
      runInAction(() => {
        this._error = (e as Error).message;
        this._isApplying = false;
      });
      return false;
    }
  }

  async reloadData() {
    await this.loadData();
  }

  getDatesWithOccurrenceForCourseSection(fromDate: Date, toDate: Date): Date[] {
    return this._calendarStore
      .getOccurrencesForCourseSection(this.editableNote.courseSectionId ?? '', fromDate, toDate)
      .map((o) => o.date);
  }

  getCourseSectionOccurrencesStartTimeForDate(date: Date): TimeOfDay[] {
    return this._calendarStore.getCourseSectionOccurrencesStartTimeForDate(
      this.editableNote.courseSectionId ?? '',
      date
    );
  }

  private async loadData() {
    runInAction(() => (this._state = 'pending'));

    try {
      await this.courseSectionsById.fetch(false);

      let note: Note | undefined;

      if (this._noteId == null) {
        note = undefined;
      } else {
        const noteLoadable = this._workStore.getNoteLoadable(this._noteId);
        await noteLoadable.fetch(true);

        if (noteLoadable.hasData) {
          note = noteLoadable.data;
        }
      }

      runInAction(() => {
        this._editableNote = new EditableNote(this._plannerId, this._newNoteDefaultValues, note);
        this._state = 'fulfilled';
      });
    } catch (e) {
      console.error(e);
      runInAction(() => (this._state = e as Error));
    }
  }

  private showPlannerItemDialog(
    navigate: NavigateFunction,
    location: Location,
    state: UserDashboardPlannerItemsLocationState
  ) {
    const newLocation = ((location.state || {}) as BackgroundLocationState)?.backgroundLocation ?? location;
    navigate(newLocation, { state, replace: true });
  }
}
