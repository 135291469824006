import LocalizedStrings from '@/resources/strings';
import { Account } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_pb';
import { PersonRounded } from '@mui/icons-material';
import { List, ListItemButton, ListItemIcon, ListItemText, SvgIcon, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

export interface AdminSchoolAddAdminAvailableUsersListProps {
  sx?: SxProps;
  className?: string;
  accounts: Account[];
  selectedAccount: Account | undefined;
  onSelectionChanged: (value: Account) => void;
  currentAdminUserIds: string[];
}

export const AdminSchoolAddAdminAvailableUsersList = observer(
  ({
    sx,
    className,
    accounts,
    selectedAccount,
    onSelectionChanged,
    currentAdminUserIds
  }: AdminSchoolAddAdminAvailableUsersListProps) => {
    const strings = LocalizedStrings.admin.general.addAdmin;

    return (
      <List sx={sx} className={className}>
        {accounts.map((account) => {
          const isSelected = selectedAccount == account;
          const isAlreadyAdmin = currentAdminUserIds.includes(account.userId);
          const hasUserId = account.userId.length > 0;

          return (
            <ListItemButton
              disabled={isAlreadyAdmin}
              selected={isSelected}
              key={account.id}
              onClick={() => onSelectionChanged(account)}
            >
              <ListItemIcon>{hasUserId ? <PersonRounded /> : <SvgIcon />}</ListItemIcon>
              <ListItemText
                primary={account.fullName}
                secondary={
                  isSelected && !hasUserId ? (
                    <>
                      <Typography variant="body2">{account.emailAddress}</Typography>
                      <Typography variant="caption" fontWeight="500" color="error">
                        {strings.noUserWarningLabel()}
                      </Typography>
                    </>
                  ) : (
                    account.emailAddress
                  )
                }
              />
            </ListItemButton>
          );
        })}
      </List>
    );
  }
);
