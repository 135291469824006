import { AdminSchoolAddAdminAvailableUsersList } from '@/components/admin/general/AdminSchoolAddAdminAvailableUsersList';
import { Dialog } from '@/components/utils';
import { useViewModel } from '@/hooks';
import LocalizedStrings from '@/resources/strings';
import { Box, Stack, SxProps, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

export interface AdminSchoolAddAdminDialogProps {
  sx?: SxProps;
  className?: string;
  isOpen: boolean;
  schoolId: string;
  currentAdminUserIds: string[];
}

export const AdminSchoolAddAdminDialog = observer(
  ({ sx, className, isOpen, schoolId, currentAdminUserIds }: AdminSchoolAddAdminDialogProps) => {
    const navigate = useNavigate();
    const strings = LocalizedStrings.admin.general.addAdmin;

    function close() {
      navigate(-1);
      return Promise.resolve();
    }

    const viewModel = useViewModel((viewModels) => viewModels.createAdminSchoolAddAdminViewModel(schoolId, close));

    return (
      <Dialog
        sx={sx}
        className={className}
        viewModel={viewModel}
        isOpen={isOpen}
        title={strings.title()}
        width="sm"
        fullHeight
        disableOverflow
        contentPadding={{ left: 0, right: 0 }}
        renderData={() => (
          <Stack spacing={1} width="100%">
            <Box sx={{ px: 2 }}>
              <TextField
                fullWidth
                value={viewModel.searchText}
                label={strings.searchLabel()}
                onChange={(e) => (viewModel.searchText = e.target.value)}
              />
            </Box>
            <AdminSchoolAddAdminAvailableUsersList
              sx={{ overflowY: 'auto', height: '100%' }}
              accounts={viewModel.availableTeachers}
              selectedAccount={viewModel.selectedTeacher}
              onSelectionChanged={(a) => (viewModel.selectedTeacher = a)}
              currentAdminUserIds={currentAdminUserIds}
            />
            <Typography sx={{ px: 2 }} variant="body2" color="text-secondary">
              {strings.userNotListedRemark()}
            </Typography>
          </Stack>
        )}
      />
    );
  }
);
