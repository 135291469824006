import { TableRowAction, TableRowActions } from '@/components/lists';
import { UserProfileIcon } from '@/components/shared';
import LocalizedStrings from '@/resources/strings';
import { UserSummary } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/user_summary_pb';
import { DeleteRounded, HomeRounded } from '@mui/icons-material';
import { ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Key } from 'react';

export interface AdminSchoolAdminUsersListItemProps {
  sx?: SxProps;
  className?: string;
  key?: Key | null;
  userSummary: UserSummary;
  isCurrentUser?: boolean;
  isOwner?: boolean;
  onMakeOwner?: () => void;
  onRemove?: () => void;
}

export const AdminSchoolAdminUsersListItem = observer(
  ({
    sx,
    className,
    key,
    userSummary,
    isCurrentUser = false,
    isOwner = false,
    onMakeOwner,
    onRemove
  }: AdminSchoolAdminUsersListItemProps) => {
    const strings = LocalizedStrings.admin.general.adminsList;

    const actions: TableRowAction[] = [
      {
        text: strings.makeOwnerButton(),
        icon: <HomeRounded />,
        selection: { case: 'action', onSelect: () => onMakeOwner?.() },
        isHidden: isOwner || onMakeOwner == null
      },
      {
        text: strings.removeAdminButton(),
        icon: <DeleteRounded color="error" />,
        selection: { case: 'action', onSelect: () => onRemove?.() },
        isHidden: isCurrentUser || isOwner || onRemove == null
      }
    ];

    return (
      <ListItem sx={sx} className={className} key={key}>
        <ListItemIcon>
          <UserProfileIcon url={userSummary.pictureUrl} userFullName={userSummary.fullName} />
        </ListItemIcon>

        <ListItemText
          primary={isOwner ? `${userSummary.fullName} (${strings.ownerLabel()})` : userSummary.fullName}
          secondary={userSummary.emailAddress}
        />

        <ListItemSecondaryAction>
          <TableRowActions actions={actions} />
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
);
